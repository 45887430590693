<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" ref="supplierForm" @submit.prevent="saveSuppliers">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" :elevation="1" height="30px" dark>
                                    <v-toolbar-title class="subtitle-2">Supplier Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col cols="5">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3">Sup. Id</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="$store.getters['supplier/newCode']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Sup. Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="supplier.name"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[() => !!supplier.name || 'Enter name']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Mobile</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="supplier.mobile"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[() => !!supplier.mobile || 'Enter mobile']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3">E-mail</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="supplier.email"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Owner Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="supplier.owner_name"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Address</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="supplier.address"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3">Previous Due</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="supplier.previous_due"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row dense>
                                                         <v-col cols="6">
                                                            <v-btn height="30px" type="reset" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn height="30px" type="submit" dark block color="light-blue darken-2" :loading="saveLoading">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Image</v-col>
                                                <v-col cols="9">
                                                    <v-file-input 
                                                        show-size 
                                                        outlined 
                                                        dense
                                                        hide-details
                                                        @change="onChangeMainImage()"
                                                        prepend-icon=""
                                                        prepend-inner-icon="mdi-camera"
                                                    >
                                                    </v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-card tile :elevation="0"> 
                                                        <v-img
                                                            :src="image"
                                                            aspect-ratio="1.4"
                                                            class="grey lighten-2"
                                                        >
                                                        </v-img>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="supplierHeaders"
                    :loading="$store.getters['supplier/loading']"
                    :items="$store.getters['supplier/suppliers']"
                    :search="searchSupplier"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Supplier List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    v-model="searchSupplier"
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Supplier"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editSupplier(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="supplierId = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteSupplier"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";
export default {
    name: 'SupplierEntry',

    components: {
		"confirm-dialog": confirmDialog,
    },

    data: ()=> ({
        apiBasePath: window.apiBaseURL,
        
        suppliers: [],
        supplier: {
            id: null,
            name: '',
            mobile: '',
            email: '',
            owner_name: '',
            address: '',
            previous_due: '',
        },
        image: '',
        imageFile: null,
        supplierId: null,
        saveLoading: false,

        supplierHeaders: [
            { text: 'Id', value: 'code' },
            { text: 'Name', value: 'name' },
            { text: 'Mobile', value: 'mobile' },
            { text: 'Email', value: 'email' },
            { text: 'Owner Name', value: 'owner_name' },
            { text: 'Address', value: 'address' },
            { text: 'Action', value: 'action' },
        ],
        searchSupplier: '',
    }),

    created() {
        this.getSuppliers();
        this.getCode();
    },

    methods: {
        onChangeMainImage() {
            if(event.target.files == undefined || event.target.files.length < 1) {
                this.image = '';
                this.imageFile = null;
                return;
            }

            this.imageFile = event.target.files[0];
            this.image = URL.createObjectURL(event.target.files[0]);
        },

        getSuppliers() {
            this.$store.dispatch('supplier/getSuppliers');
        },

        getCode() {
            this.$store.dispatch('supplier/getCode');
        },

        async saveSuppliers() {
            if(! this.validationForm()) {
                return;
            }

            this.saveLoading = true;
            let isSuccess = await this.$store.dispatch('supplier/saveSuppliers', { supplier: this.supplier, image: this.imageFile });

            if(isSuccess) {
                this.resetForm();
            }

            this.saveLoading = false;
        },

        async editSupplier(supplier) {
            Object.keys(this.supplier).forEach(key => this.supplier[key] = supplier[key]);
            this.image = `${this.apiBasePath}/${supplier.image}`;
        },

        deleteSupplier() {
            this.$store.dispatch('supplier/deleteSupplier', { id: this.supplierId });
            this.$refs.confirmDialog.dialog = false;
        },

        validationForm() {
            let isValid = true;
            this.$refs.supplierForm.validate();
            this.$refs.supplierForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            return isValid;
        },

        resetForm() {
            this.$refs.supplierForm.reset();
            this.$refs.supplierForm.resetValidation();
            this.getCode();
        }

    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
</style>